.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 25px 0;
}

section {
  padding: 25px 0;
  border-bottom: 1px solid #e1e1e1;
}

ul {
  list-style-type: none;
  margin: 0;
}

button {
  margin: 0 5px;
}

input { 
  padding: 5px;
}

.response {
  margin: 0 auto;
  width: 400px;
  padding: 10px;
  border: 1px solid #e1e1e1;
  word-wrap: break-word;
}

.response:empty {
  display: none;
}
